import SharedSettings from "../common/sharedSettings.json";

const NLD = {
    common: {
        AccessWorkflow: "Toegang tot workflow",
        AccessStationsGrid: "Toegang tot stationsraster",
        AccessStationsMap: "Toegang tot stationskaart",
        AccessZonesGrid: "Toegang tot zonesraster",
        AccessZonesMap: "Toegang tot zoneskaart",
        Actions: "Acties",
        Add: "Toevoegen",
        AddAnElement: "Een element toevoegen",
        AddSubEntity: "Subentiteit toevoegen",
        Assign: "Een rol toewijzen",
        AssignToInternalUsers: "Toewijzen aan interne gebruikers",
        AssignToExternalUsers: "Toewijzen aan externe gebruikers",
        AlertsOnly: "Alleen meldingen",
        AlertsToSee: 
            "Er zijn meldingen over dit element. Meer info in het raster.",
        ApplicationDown: "Applicatie is momenteel in onderhoud",
        ApplicationUnderMaintenance: "App is in onderhoud van %{startDate} tot %{endDate}",
        Back: "Terug",
        Bookmarks: "Bladwijzers",
        Cancel: "Annuleren",
        CannotAccessThisPage: "Kan deze pagina niet openen",
        Continue: "Doorgaan",
        ChangesHistory: "Geschiedenis van wijzigingen",
        CoordinateSystem: "Coördinatensysteem",
        CreatingNew: "Een nieuwe maken",
        DefineA: "Definieer een",
        Delete: "Verwijderen",
        DeselectAll: "Alles deselecteren",
        DontHaveAccessToAnyEntity: "U hebt momenteel geen toegang tot een entiteit",
        Download: "Downloaden",
        Edit: "Bewerken",
        Editing: "Bewerken van de",
        AddingEnglish: "Engelse waarde toevoegen voor",
        EditEntityExtent: "Bewerk de omvang van de entiteit",
        EditZone: "Zone bewerken",
        EditTreeVisibility: "Boomzichtbaarheid bewerken",
        ExcelExport: "Excel exporteren",
        False: "Onwaar",
        FullName: "Volledige naam",
        GetResults: "Resultaten ophalen",
        GINTExport: "gINT exporteren",
        GridView: "Rasterweergave",
        Group: "Groep",
        Help: "Help",
        Hidden: "Verborgen",
        Loading: "Laden...",
        Location: "Locatie",
        Mandatory: "Verplicht",
        MapView: "Kaartweergave",
        Move: "Verplaatsen",
        New: "Nieuw",
        entitydelete: "Hiermee wordt de entiteit verwijderd",
        operationundone: "Deze bewerking kan niet ongedaan worden gemaakt.",
        entitycode: "Voer ter bevestiging de code van de entiteit in:",
        NoResults: "Geen resultaten om weer te geven",
        OneTsigane: "Een Tsigane",
        OpenNotifications: "Meldingen openen",
        Overwrite: "Overschrijven",
        QuickSearchInto: "Snel zoeken in:",
        QuickSearchFilter: "Opties voor snelzoekfilter",
        ParentFieldIsRequired: "Het ouderveld is verplicht.",
        PreviewFile: "Voorbeeld van het bestand",
        Private: "Privé",
        RedirectTo: "Doorverwijzen naar:",
        Refresh: "Vernieuwen",
        ResultsFor: "Resultaten voor %{value}",
        RemoveAccessRight: "Toegangsrechten verwijderen",
        Search: "Zoeken",
        SearchInTable: "Zoeken in tabel",
        SeeAlerts: "Bekijk meldingen",
        SeeMore: "Meer zien",
        Select: "Selecteren",
        SelectAll: "Alles selecteren",
        SettingsPanel: "Instellingenpaneel",
        Skip: "Overslaan",
        SkipUpload: "Uploaden overslaan",
        Close: "Sluiten",
        Remove: "Verwijderen",
        Request: "Verzoek",
        Role: "Rol",
        newRef: "Verzoek om een nieuwe referentie",
        addNewRef: "Vraag een nieuwe referentie aan",
        Save: "Opslaan",
        Translate: "Vertalen",
        Tree: "Boom",
        True: "Waar",
        Workflow: "Workflow",
        GenerateAToken: "Genereer een token",
        CopytoClipboard: "Kopiëren naar klembord",
        CopiedSuccessfully: "Succesvol gekopieerd",
		SavedSuccessfully: "Succesvol opgeslagen",
		featuresmessage: `Een nieuwe versie (versie ${SharedSettings.Version}) van de applicatie is uitgebracht. Deze bevat de volgende nieuwe functies:`,
		Error: "Foutrapport",
		Run: "Rennen",
    },
    contact: {
        FileUploadedSuccessfully: "%{fileName} succesvol geüpload",
        NoFileToDownload: "Geen bestand om te downloaden!",
        YouHaveAProblem: "U heeft een probleem",
        ContactForm: "Contactformulier",
        YouHaveAnIdea: "U heeft een idee",
        QuestionAboutTheApp: "Een vraag over de app",
        WantToTrackAProblem: "Een probleem volgen",
        QuestionAboutUsingTheToolWithBranches: "Een vraag over het gebruik van de tool met filialen",
        BranchManagers: "Filiaalbeheerders"
    },
    diagram: {
        Draft: "Concept",
        Failed: "Mislukt",
        Final: "Definitief",
        Finalize: "Afronden",
        ForValidation: "Ter goedkeuring",
        New: "Nieuw",
        NotValidated: "Niet gevalideerd",
        NotValid: "Niet geldig",
        OK: "OK",
        Ok: "Ok",
        Processing: "Verwerken",
        Reject: "Afwijzen",
        Rejected: "Afgewezen",
        Remove: "Verwijderen",
        Removed: "Verwijderd",
        Requested: "Aangevraagd",
        Reset: "Resetten",
        Rework: "Herwerken",
        Reviewed: "Beoordeeld",
        Submit: "Indienen",
        Submitted: "Ingediend",
        UnderValidation: "Onder validatie",
        Validated: "Gevalideerd",
        Validate: "Valideren",
        Valid: "Geldig",
        Waiting: "Wachten",
    },
    eddDataValidation: {
        Stations: "Stations",
        Samplings: "Monsters",
        InSituUndergroundMeasObs: "In Situ Ondergrondse Metingen",
        LaboResult: "Lab Resultaten",
        SpeciesIdentification: "Soortenidentificatie",
        EddValidationMayTakeSomeMinutes: "Het laden kan enkele minuten duren, afhankelijk van de hoeveelheid gegevens in de EDD."
    },
    enum: {
        unitCategories: {
            NONE: "Geen",
            CONDUCTANCE: "Geleidingsvermogen",
            CONDUCTIVITY: "Geleiding",
            FLAMMABILITY: "Ontvlambaarheid",
            HIGHT_DEPTH: "Hoogte/Diepte",
            LENGTH: "Lengte",
            MASS: "Massa",
            MASS_CONCENTRATIONS: "Massaconcentraties",
            MASS_FRACTIONS: "Massa fracties",
            MICROBIAL_CELLS_CONCENTRATION: "Concentratie van microbiële cellen",
            PARTS_PER_MASS: "Delen per: Massa",
            PARTS_PER_VOLUME: "Delen per: Volume",
            PERCENTAGE: "Percentage",
            PRESSURE: "Druk",
            SPEED: "Snelheid",
            TEMPERATURE: "Temperatuur",
            VOLTAGE: "Spanning",
            VOLUME: "Volume",
            VOLUME_FLOW_RATE: "Volume debiet",
            WATER_HARDNESS: "Waterhardheid",
            SPECIFIC_VOLUME: "Specifiek volume",
            SOUND_INTENSITY: "Geluidsintensiteit",
            RADIOACTIVITY_DESINTEGRATION_RATE: "Radioactiviteit desintegratiesnelheid",
            RADIOACTIVITY_PARTICLE_FLUX: "Radioactiviteit deeltjesflux"
        },
        frequencyQuery: {
            Monday: "Elke maandag",
            Friday: "Elke vrijdag",
            BeginningMonth: "Elke begin van de maand",
            EndMonth: "Elke eind van de maand",
        },
        rights: {
            COMPANYMANAGER: "Bedrijfsmanager",
            HOLDINGMANAGER: "Holdingmanager",
            ENTITYMANAGER: "Entiteitsmanager",
            BRANCHMANAGER: "Filiaalmanager",
            CONTRIBUTOR: "Bijdrager",
            READER: "Lezer",
            EXTERNAL: "Extern",
            GISMANAGER: "GIS-manager",
        },
    },
    field: {
        AdditionalExplanations: "Aanvullende uitleg",
        AnalysisGroup: "Analysegroep",
        Author: "Auteur",
        Basemap: "Basemap",
        Branch: "Filiaal",
        Cas: "CAS-nummer",
        Category: "Categorie",
        Code: "Code",
        Color: "Kleur",
        Confidential: "Vertrouwelijk",
        Country: "Land",
        CreatedOn: "Aanmaakdatum",
        CreatedByUser: "Gemaakt door gebruiker",
        CreatedByUserDescription: "Identiteit van de gebruiker die het item heeft aangemaakt",
        Description: "Beschrijving",
        DocumentCategory: "Documentcategorie",
        EddFile: "EDD-bestand",
        EndDate: "Einddatum",
        Entity: "Entiteit",
        EntityCode: "Entiteitscode",
        EntityName: "Entiteitsnaam",
        External: "Extern",
        ExternalProvider: "Externe aanbieder",
        Explaination: "Uitleg",
        Family: "Familie",
        File: "Bestand",
        Filename: "Bestandsnaam",
        Firstname: "Voornaam",
        Frequency: "Frequentie",
        GisManager: "GIS-beheerder",
        GroundAltitude: "Grondhoogte",
        InitialStudy: "Initieel onderzoek",
        IsPrimaryEDDFile: "Primair document van de EDD",
        Key: "Sleutel",
        Language: "Taal",
        Lastname: "Achternaam",
        LastUpdate: "Laatste update",
        LastUpdateDescription: "Datum en tijd van de laatste update",
        LookUpTableName: "Zoektabelnaam",
        MapService: "Kaartdienst",
        MaxValue: "Maximale waarde",
        MinValue: "Minimale waarde",
        Name: "Naam",
        No: "Nee",
        NodeType: "Knooppunttype",
        Note: "Opmerking",
        Number: "Nummer",
        Observation: "Observatie",
        Owner: "Eigenaar",
        Parent: "Ouder",
        Phone: "Telefoon",
        PhotoContext: "Foto-context",
        PointCoordinates: "Puntcoördinaten",
        PolygonCoordinates: "Polygonencoördinaten",
        PublicationDate: "Publicatiedatum",
        Public: "Publiek",
        QueryName: "Querynaam",
        Reference: "Referentie",
        Region: "Regio",
        RelatedZones: "Gerelateerde zones",
        RelatedStations: "Gerelateerde stations",
        RelatedStationGroups: "Gerelateerde stationgroepen",
        RelatedStudies: "Gerelateerde studies",
        RequestedAccess: "Aangevraagde toegang",
        RequestAccessType: "Aanvraagtype",
        RequestDate: "Aanvraagdatum",
        RequestorIGG: "Aanvrager IGG",
        RequestType: "Aanvraagtype",
        ResultsSize: "Resultaatgrootte",
        SamplingID: "Monstering-ID",
        SubSamplingID: "Sub-monstering-ID",
        SampleType: "Monstype",
        SecurityGroupType: "Beveiligingsgroepstype",
        Share: "Delen",
        StartDate: "Startdatum",
        StartEndDate: "Start- / Einddatum",
        State: "Status",
        Station: "Station",
        StationAir: "Lucht",
        StationBiodiversity: "Biodiversiteit",
        StationCode: "Stationscode",
        StationGroups: "Stationgroepen",
        StationNoise: "Geluid",
        StationSedimentWater: "Water & Sediment",
        StationSediment: "Sediment",
        StationWater: "Water",
        StationSoilGroundwater: "Bodem-Grondwater",
        StationType: "Stationstype",
        Study: "Onderzoek",
        StudyType: "Onderzoekstype",
        StudyYear: "Onderwijsjaar",
        Submitter: "Indiener",
        Title: "Titel",
        UpdatedByUser: "Bijgewerkt door gebruiker",
        UpdatedByUserDescription: "Identiteit van de laatste gebruiker die het element heeft bijgewerkt",
        User: "Gebruiker",
        Units: "Eenheden",
        Value: "Waarde",
        Valid: "Geldig",
        WorkOrder: "Werkorder",
        Yes: "Ja",
        Zones: "Zones",
        EmailNotif: "Ontvang meldingen per e-mail",
        ExplanatoryText: "Technische token voor toegang tot de API's van One-Tsigane. Deze token is strikt persoonlijk: Uw identiteit wordt geregistreerd bij gebruik. Het is 365 dagen geldig:",
    },
    grid: {
        title: {
            Administration: "Administratie",
            Alerts: "Meldingen",
            AllUsers: "Alle gebruikers",
            Analytes: "Analyten",
            Branches: "Filialen",
            BranchManagers: "Filiaalmanagers",
            CompanyManagers: "Bedrijfsmanagers",
            HoldingManagers: "Holdingmanagers",
            Configuration: "Configuratie",
            UserPref: "Gebruikersinstellingen",
            Countries: "Landen",
            Documents: "Documenten",
            EDDFiles: "EDD-bestanden",
            Entities: "Entiteiten",
            EntityManagers: "Entiteitsmanagers",
            EntityUsers: "Entiteitsgebruikers",
            ExternalUsers: "Externe gebruikers",
            ExternalProviders: "Externe aanbieders",
            Families: "Families",
            Files: "Bestanden",
            GISManagers: "GIS-beheerders",
            InternalUsers: "Interne gebruikers",
            LimitsOf: "Limieten van",
            LookupHints: "Zoekhints",
            NewFeaturesMessage: "Nieuwe functies Bericht",
            PhotoVideo: "Foto's en video's",
            Queries: "Vragen",
            Regions: "Regio's",
            Requests: "Aanvragen ter validatie",
            MyRequests: "Mijn aanvragen",
            ReportScheduler: "Rapportageplanner",
            Settings: "Instellingen",
            Samplings: "Monsteringen",
            Stations: "Stations",
            VisibilityEditor: "Zichtbaarheidseditor",
            WorkorderExternalUsers: "Beheer externe gebruikersmachtigingen voor Onderzoek \"%{value}\", Werkorder \"%{value2}\"",
            Zones: "Zones",
			BulkUpload: "Bulk uploaden",
        },
    },
    tree: {
        Add: "Voeg kindknooppunt toe aan %{value}",
        AddBookmark: "Voeg toe aan bladwijzers",
        AddStudy: "Voeg een studie toe aan %{value}",
        AddStationGroup: "Voeg een stationgroep toe",
        Delete: "Verwijder %{value}",
        Edit: "Bewerk %{value}",
        EditTranslation: "Bewerk %{value} vertalingen",
        Entities: "Entiteiten",
        EntityTreeTemplate: "Entiteit Boom Sjabloon",
        EntityTreeTemplateEditor: "Entiteit Boom Sjabloon Editor",
        Limits: "Limieten",
        Lookups: "Zoekopdrachten",
        Maps: "Kaarten",
        References: "Referenties",
        RemoveFromBookmarks: "Verwijder uit bladwijzers",
        Security: "Beveiliging",
        StationGroupNodeEmpty: 
            "Momenteel zijn er geen stationgroepen om weer te geven. Klik hier om een nieuwe te maken",
        StudyTemplate: "Studie Sjabloon",
        StudyTemplateEditor: "Studie %{value} sjabloon editor",
        Users: "Gebruikers",
        Requests: "Aanvragen",
        StudyType: "Onderzoekstype",
        TextType: "Teksttype"
    },
    dialog: {
        Alerts: "Meldingen",
        AssignTo: "Wijs %{value} toe aan een %{value2}",
        AddNewLimits: "Voeg nieuwe limieten toe.",
        CopyZonesFromStudies: "Kopieer zones van andere studies.",
        OverwriteFileConfirmation: 
            "Dit bestand bestaat al. Weet je zeker dat je de lopende upload wilt overschrijven?",
        PromptChangesHasOccured: 
            "Weet je zeker dat je deze pagina wilt verlaten?\n\nJe bent begonnen met het schrijven of bewerken van een rij in de huidige gegevensrooster.\n\nDruk op OK om door te gaan of Annuleren om op de huidige pagina te blijven en je wijzigingen op te slaan.",
        DeleteConfirmationRow: "Weet je zeker dat je deze rij wilt verwijderen?",
        DeleteConfirmationNode: "Weet je zeker dat je dit knooppunt wilt verwijderen?",
        Cancelled: "Geannuleerd",
        SessionVerification: 
            "Je sessie wordt gecontroleerd. Bedankt voor je geduld.",
        UploadSkipped: "Upload overgeslagen",
        NotificationDoneConfirmation: 
            "Weet je zeker dat je deze melding wilt valideren?",
    },
    input: {
        validation: {
            required: "Dit veld is verplicht.",
            DateRangeValidation: "De startdatum moet vóór de einddatum liggen.",
            DropFileAccepted: "Laat de bestanden vallen",
            DropFileRejected: `Sorry, alleen ${SharedSettings.MaxFilesToUpload} bestanden zijn toegestaan per keer`,
            NumberChar: "Het maximum aantal tekens in dit veld is: ",
            WrongDocumentExtension: `Dit is een documentmap: de geaccepteerde bestanden mogen geen afbeeldingen of video's zijn, hun upload wordt daarom geweigerd.`,
            WrongPhotoVideoExtension: `Dit is een foto/video-map: de geaccepteerde bestanden moeten afbeeldingen of video's zijn, anders wordt hun upload geweigerd.`,
            InvalidStartingDate: 
                "De startdatum mag niet na de einddatum liggen.",
            InvalidEndingDate: 
                "De einddatum mag niet vóór de startdatum liggen.",
            InvalidRange: "De waarde komt niet overeen met het bereik",
            InvalidTime: "De ingevoerde tijd is onjuist. Controleer het opnieuw. Het geldige tijdsbereik is van 00:00 tot 23:59.",
            SelectStudy: "Selecteer eerst een studie",
        },
    },
    languages: {
        Dutch: "Nederlands",
        English: "Engels",
        French: "Frans",
        German: "Duits",
        Portuguese: "Portugees",
        Spanish: "Spaans",
    },
    map: {
        addAPoint: "Voeg een nieuw punt toe",
        coordinates: "Coördinaten",
        errorSIG: "De verbinding met SIG kon niet worden gemaakt.",
        errorLayerSIG: 
            'De verbinding met de SIG-laag "%{layerName}" kon niet worden gemaakt.',
        entityOfInterest: "Entiteit van belang",
        extentOf: "Omvang van",
        latitude: "Breedtegraad",
        longitude: "Lengtegraad",
        mapServerLayers: "Kaartserverlagen",
        point: "Punt",
        polygon: "Polygoon",
        removeAPoint: "Verwijder een punt",
        redirectToStation: "Bekijk details van het station",
        mapServerLayersTypes: {
            LAYER_PICTURE: "Foto's",
            LAYER_PLAN: "Plannen",
            LAYER_TOPO: "Topografieën",
            LAYER_PIPELINES: "Leidingen",
            LAYER_MISC: "Diversen",
            LAYER_CADASTRE: "Kadaster",
            LAYER_ZONE: "Zones",
        },
        selectedStations: "Geselecteerde stations",
        stationGroupsLayers: "Stationgroep lagen",
        stationsLayers: "Stationslagen",
        stationsOf: "Stations van",
        stationsOfGroup: "Stations van groep",
        zonesOf: "Zones van",
        zonesExtents: "Zoneomvangen",
    },
    notification: {
        noNotification: "Je hebt geen meldingen!",
        errorNotifictation: "Meldingen zijn momenteel niet beschikbaar",
    },
    misc: {
        lambdaUser: "Lambda-gebruiker",
        From: "van:",
    },
    panel: {
        EditEntity: "Bewerk entiteit",
        NewEntity: "Nieuwe entiteit",
        NewSubEntity: "Nieuwe sub-entiteit"
    },
    placeholder: {
        Logout: "Uitloggen",
        NoEntry: "Geen toegang",
        UserState: "Gebruikersstatus",
        EddFileState: "EDD-bestandstatus",
    },
    select: {
        Contributor: "Bijdrager",
        Reader: "Lezer",
        External: "Extern",
    },
    view: {
        entityPage: {
            Code: "Code",
            Name: "Naam",
            Country: "Land",
            Branch: "Filiaal",
            Parent: "Ouder",
            Contact: "Contacten",
            Documents: "Documenten",
            History: "Geschiedenis",
            Home: "Startpagina",
            Location: "Locatie",
            Operations: "Operaties",
            Photo: "Foto",
        },
        helpPage: {
            Contact: "Contact",
            Documents: "Documenten",
            InternalUsers: "Interne gebruikers",
            ExternalUsers: "Externe gebruikers",
            contact: {
                FileUploadedSuccessfully: "%{fileName} succesvol geüpload",
                NoFileToDownload: "Geen bestand om te downloaden!",
                AnyTechnicalIssues: "Technische problemen",
                PleaseClickHere: "Klik hier",
                AnyFurtherFunctionalities: "Wilt u verdere functionaliteiten op het systeem?",
                PleaseReferToThisLink: "Verwijs naar deze link",
                QuestionAboutTheApp: "Een vraag over de app",
                WantToTrackAProblem: "Wil je een probleem volgen?",
                ForSupportAtBranchLevel: "Voor ondersteuning op filiaalniveau, neem contact op met",
            },
        },
        newUser: {
            CurrentRequests: "Huidige aanvragen",
            EntityAccessRequest: "Verzoek om toegang tot een bestaande entiteit",
            EntityCreateRequest: "Verzoek om een nieuwe entiteit te creëren",
            Welcome: "Welkom",
        },
        document: {
            AddMediaMetadata: "Voeg metadata toe aan:",
            AddDocument: "Voeg document toe",
            CancelMove: "Annuleer verplaatsing",
            CurrentStudy: "Huidige studie:",
            Destination: "Bestemming",
            DragAndDropFile: `Sleep een bestand over het scherm om te uploaden, of gebruik de knop 'Toevoegen' op de datagrid-toolbalk.`,
            Extension: "Extensie",
            FilesRejected: "Bestanden worden geweigerd.",
            HelpFileUpload: "Hulp bij het uploaden van bestanden",
            MaxFilesAllowed: `Een uploadactie staat maximaal ${SharedSettings.MaxFilesToUpload} bestanden per keer toe.`,
            MoveDocument: "Verplaats een document",
            OriginalStudy: "Oorspronkelijke studie:",
            MoreInfo: "Meer info",
            ListAllowedExtensions: "Lijst van toegestane bestandsextensies",
            SelectDestination: "Selecteer bestemming",
            UploadedBy: "Geüpload door:",
        },
        eddFiles: {
            getTemplate: "Haal bestandssjabloon op",
        },
        limit: {
            add: "Voeg een nieuwe limiet toe",
            addNewLimits: "Voeg nieuwe limieten toe",
            limitSelection: "Definitie en selectie van object",
            stepper: {
                CreateNewLimit: "Maak een nieuwe limiet",
                EditLimit: "Bewerk limiet",
            },
            objectSelection: {
                object: "Object",
                parentObject: "Ouderobject",
            },
        },
        query: {
            ranOn: "Gedraaid op",
            ranOnThe: "gedraaid op de",
            reporting: "Rapportage",
            resultQuery: "Resultaat van de query",
            runQuery: "Voer de query uit",
            seeResultsOnMap: "Bekijk deze resultaten op een kaart",
            stepper: {
                CreateNewQuery: "Maak een nieuwe query",
                EditQuery: "Bewerk query",
            },
            objectSelection: {
                objectSelection: "Objectselectie",
                object: "Object",
                parentObject: "Ouderobject",
                childObject: "Kindobject",
                removeChild: "Verwijder kind",
            },
            conditions: {
                conditions: "Voorwaarden",
            },
            outputSelection: {
                outputSelection: "Uitvoerselectie",
                Fieldname: "Veldnaam",
                Label: "Label",
                Description: "Beschrijving",
                Show: "Toon",
            },
        },
        request: {
            ADDUSER: "Voeg een gebruiker toe",
            NEWENTITY: "Nieuwe entiteit",
            NEWREF: "Nieuwe referentie",
            OptionalObservation: "Optionele observatie",
        },
        station: {
            CreateNewStation: "Maak nieuw station",
            CreateStationGroup: "Maak een groep van stations",
            EditStation: "Bewerk station",
            StationGroup: "Stationgroep",
            StationStationGroup: "Stations van stationgroep:",
            StationGroupLoading: "Stations van stationgroep: laden...",
        },
        study: {
            Study: "de studie",
            StudyWorkOrder: "Studie %{value}: Werkorders",
            NoStudyWorkOrder: "Studie ...: Werkorders",
        },
        workOrder: {
            EDDFiles: "EDD-bestanden",
            ExternalUsersPermissions: "Permissies voor externe gebruikers",
        },
        zone: {
            CopyZones: "Kopieer zones",
            CopyZonesFromStudy: "Kopieer zones van een andere studie",
            CreateNewZone: "Maak nieuwe zone voor",
            EditExtent: "Bewerk de omvang van",
            EditZone: "Bewerk zone",
            ExtendDefined: "Omvang gedefinieerd",
            ExtendNotDefined: "Omvang niet gedefinieerd",
            LoadingEntity: "Entiteit laden...",
            PreExistingZones: "Bestaande zones van",
        },
    },
    packages: {
        colorPicker: {
            language: "NLD",
            color: "kleur",
            rgb: "rgb",
            R: "R",
            G: "G",
            B: "B",
            hsv: "hsv",
            H: "H",
            S: "S",
            V: "V",
            hsl: "hsl",
            WrongFormat: "Verkeerd formaat.",
            NotHexFormat: "Geen hex-waarde.",
            transparent: "Transparant",
            none: "Ongeldig",
        },
        reactAwesomeQueryBuilder: {
            and: "en",
            or: "of",
            valueLabel: "Waarde",
            valuePlaceholder: "Waarde",
            fieldLabel: "Veld",
            operatorLabel: "Operator",
            funcLabel: "Functie",
            fieldPlaceholder: "Selecteer veld",
            funcPlaceholder: "Selecteer functie",
            operatorPlaceholder: "Selecteer operator",
            lockLabel: "Vergrendel",
            lockedLabel: "Vergrendeld",
            deleteLabel: "",
            delGroupLabel: "",
            addGroupLabel: "Voeg groep toe",
            addRuleLabel: "Voeg regel toe",
            addSubRuleLabel: "Voeg subregel toe",
            notLabel: "Niet",
            valueSourcesPopupTitle: "Selecteer waarde bron",
            removeRuleConfirmOptions: {
                title: "Weet je zeker dat je deze regel wilt verwijderen?",
                okText: "Ja",
                okType: "danger",
                cancelText: "Annuleren",
            },
            removeGroupConfirmOptions: {
                title: "Weet je zeker dat je deze groep wilt verwijderen?",
                okText: "Ja",
                okType: "danger",
                cancelText: "Annuleren",
            },
            like: "zoals",
            not_like: "niet zoals",
            starts_with: "begint met",
            ends_with: "eindigt met",
            between: "tussen",
            not_between: "niet tussen",
            is_null: "is null",
            is_not_null: "is niet null",
            is_empty: "is leeg",
            is_not_empty: "is niet leeg",
            select_any_in: "enige in",
            select_not_any_in: "niet in",
            proximity: "nabijheid",
            enterValue: "Voer waarde in",
            enterNumber: "Voer nummer in",
            enterNumberFrom: "Voer nummer vanaf",
            enterNumberTo: "Voer nummer tot",
            enterDate: "Voer datum in",
            enterInteger: "Voer geheel getal in",
            enterText: "Voer tekst in",
            selectValue: "Selecteer waarde",
            selectValues: "Selecteer waarden",
        },
    },
    references: {
        Ref_AirType: "Lucht Type",
        Ref_AnalysisGroup: "Analyse Groep",
        Ref_AquiferStatus: "Aquifer Status",
        Ref_BiodiversityType: "Biodiversiteit Type",
        Ref_BoreholeStatus: "Boorput Status",
        Ref_BoreholeType: "Boorput Type",
        Ref_CapType: "Deksel Type",
        Ref_ConstructionType: "Constructie Type",
        Ref_Context: "Context",
        Ref_DayNight: "Dag en Nacht",
        Ref_DrillingFluid: "Boorgatvloeistof",
        Ref_DrillingMethod: "Boormethode",
        Ref_EcologicalGroup: "Ecologische Groep",
        Ref_EcologicalIntegrity: "Ecologische Integriteit",
        Ref_EmissionSource: "Emissiebron",
        Ref_EquipmentType: "Apparaat Type",
        Ref_FaunaUse: "Fauna Gebruik",
        Ref_GeometryType: "Geometrie Type",
        Ref_HabitatType: "Habitat Type",
        Ref_HumanUse: "Menselijk Gebruik",
        Ref_Installation: "Installatie",
        Ref_IUCNStatus: "IUCN Status",
        Ref_LithologyType: "Lithologie Type",
        Ref_Matrix: "Matrix",
        Ref_ObsMeasure: "Obs Meting",
        Ref_ObservationMode: "Observatiemodus",
        Ref_ObservationType: "Observatietype",
        Ref_PhotoContext: "Foto Context",
        Ref_Parameter: "Parameter",
        Ref_PiezometerGroup: "Piezometer Groep",
        Ref_PresenceProof: "Aanwezigheidsbewijs",
        Ref_QAQCType: "QAQC Type",
        Ref_ReadingType: "Meettype",
        Ref_RecordPeriod: "Registratieperiode",
        Ref_RelativePosition: "Relatieve Positie",
        Ref_ReleaseLocation: "Vrijgave Locatie",
        Ref_SampleCollectionType: "Monsterverzameltype",
        Ref_SampleType: "Monstertype",
        Ref_SensorType: "Sensor Type",
        Ref_StationBiodiversityType: "Station Biodiversiteit",
        Ref_StationSedimentWaterType: "Station Sediment Water",
        Ref_StratigraphicClassification: "Stratigrafische Classificatie",
        Ref_TrophicGroup: "Trofische Groep",
        Ref_Unit: "Eenheden",
        Ref_Vulnerability: "Kwetsbaarheid",
        Ref_WaterIntermittence: "Water Intermitterend",
        Ref_WaterLocation: "Waterlocatie",
        Ref_WaterSource: "Waterbron",
        Ref_WaterType: "Watertype",
        Ref_WeightBasis: "Gewichtsbasis",
    },
};
export default NLD;   
