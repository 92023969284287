import React, { useState, useEffect } from "react";
import {
    Box,
    Chip,
    Paper,
    ClickAwayListener,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    TextField,
    Popper,
    Grid,
    DialogTitle,
    Typography,
    CircularProgress,
    IconButton,
} from "@mui/material";
import DataGridSearchIcon from "@mui/icons-material/ManageSearch";
import {
    useCheckAndRevalidateToken,
    useDebounceEffect,
} from "../../js/utils/customHooks";
import { DiagramTransitions } from "../Widgets/DiagramTransitions";
import { Prompt } from "react-router";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { FormProvider, useForm } from "react-hook-form";
import { editModel, fetchData } from "../../js/utils/backend";
import { useSelector } from "react-redux";
import { HiddenField, VTextField } from "../Widgets/Custom Inputs/CustomInputs";
import {
    languagesOptions,
    requestedAccessOptions,
} from "../../js/constants/selectOptions";
import { VSelect } from "../Widgets/Custom Inputs/Select";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { BasicButton } from "../Widgets/Custom Inputs/Buttons";
import { t } from "react-i18nify";
import { DialogActionsForm } from "../Widgets/CustomSurfaces";
import { isArrayLength, mergeFormObjects } from "../../js/utils/genericMethods";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import { SessionVerificationDialog } from "../Layouts/CommonLayout";

export let defaultGridConfig = {
    query: {
        pageSize: 10,
        pageNumber: 0,
        search: "",
        orderBy: null,
        orderDirection: "asc",
    },
    rowHeight: 38,
};

/** White editing, will disable the edition of the field "Key" in most of References data grids. The field is editable in the data grid inline row only when it's a "NEW" row (hasn't an ID yet) */
export const disableKeyCellEdit = (params) => {
    if (params.field === "Key") {
        if (params.id === "NEW") {
            return true
        } else if (params.cellMode === "view") {
            return false
        }
    } else {
        return true
    }
}
export const filterValidUser = `filter[State]=Valid`

export const PromptDialog = ({ editing, message }) => {
    window.onbeforeunload = () => editing || null;

    return (
        <Prompt
            when={editing}
            message={(location, action) => {
                window.onbeforeunload = () => null;
                if (action === "REPLACE") return true;
                return editing
                    ? location.state?.disablePrompt ?? message
                    : true;
            }}
        />
    );
};

export const SearchInTable = ({ searchText, setSearchText, disabled }) => {
    const [state, setState] = useState(searchText);
    useDebounceEffect(() => {
        if (state !== searchText) setSearchText(state);
    }, 400)();

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "flex-end",
                position: "relative",
                mb: 1,
                mr: 5,
            }}
        >
            <DataGridSearchIcon
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
            />
            <TextField
                id="input-with-sx"
                label={t("common.SearchInTable")}
                variant="standard"
                sx={{ width: 180 }}
                value={state}
                onChange={e => setState(e.target.value)}
                disabled={disabled}
                InputProps={{
                    endAdornment: state && (
                        <IconButton sx={{ p: 0 }} onClick={() => setState("")}>
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
            />
        </Box>
    );
};

export const DeleteAction = ({ deleteAction, disabled, ...props }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };
    const handleDelete = () => {
        deleteAction();
        setOpen(false);
    };

    return (
        <>
            <GridActionsCellItem
                icon={<DeleteIcon />}
                onClick={() => handleClickOpen()}
                label="Delete"
                disabled={disabled}
                {...props}
            />
            <Dialog
                open={open}
                onClose={handleCancel}
                aria-labelledby="delete-dialog"
                aria-describedby="delete-dialog-message"
            >
                <DialogContent>
                    <DialogContentText id="delete-dialog-message">
                        {t("dialog.DeleteConfirmationRow")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>{t("common.Cancel")}</Button>
                    <Button onClick={handleDelete} color="secondary">
                        {t("common.Delete")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export const StateChip = ({ state, ...props }) => {
    return (
        <Chip
            variant="outlined"
            style={{
                backgroundColor: state?.Color,
                color: state?.TextColor,
                minWidth: 100,
                border: `1px solid ${state?.Border ?? `rgba(0, 0, 0, 0.2)`}`,

                borderRadius: "4px",
            }}
            label={t(`diagram.${state?.Name}`)}
            {...props}
        />
    );
};

/** The validation chip will allow user to make a transition, according to the current workflow of the data grid.  DOC*/
export const ValidationChip = ({
    hasRights,
    state,
    availableTransitions,
    rowData,
    baseURL,
    beforeTransition = _transition => {
        /* The parent's methods will be executed before a transition. E.g. run the loading dialog. */
    },
    afterTransition = _transition => {
        /* The parent's methods will be executed after a transition. E.g. refresh datagrid or update entity tree. */
    },
    transitionDialog = () => {
        return null;
    }
}) => {
    const [canevasAnchor, setCanevasAnchor] = useState(null);
    const isCanevasOpen = Boolean(canevasAnchor);
    const [isLoading, setIsLoading] = useState(false);
    const sheetName = useSelector(state => state.userPref.SheetName);
    const percentage = useSelector(state => state.userPref.EddDataValidationProgress);
    let progress = `${t(sheetName)}: ${percentage}%`;
    
    return (
        state && (
            <div onMouseLeave={() => setCanevasAnchor(null)}>
                <StateChip
                    onMouseEnter={event =>
                        setCanevasAnchor(event.currentTarget)
                    }
                    state={state}
                />
                
                <Dialog open={isLoading}
                    PaperComponent={Box}
                >
                    {state.Name==="Draft" ? 
                    <DialogContent style={{
                        overflow: "hidden",
                        backgroundColor: "white",
                        border: "1px solid white",
                        borderRadius: "5px",
                        textAlign: "center",
                        padding: "50px 150px"
                    }}>
                        <CircularProgress
                            size={100}
                            sx={{
                                top: 0,
                                mb: 2,
                            }}
                            color={"secondary"}
                            />
                            <Box sx={{ typography: "subtitleMajor" }}>
                                {/* {t("eddDataValidation.ValidatingSheetsData", {
                                    sheetName: t(sheetName)
                                })} */}
                                {progress}
                            </Box>
                            <Box sx={{ typography:"body1", mt:3 }}>
                                {t("eddDataValidation.EddValidationMayTakeSomeMinutes")}
                            </Box>
                    </DialogContent>
                    :
                    <DialogContent style={{ overflow: "hidden" }}>
                        <CircularProgress
                            size={100}
                            sx={{
                                top: 0,
                                mb: 2,
                            }}
                            color={"secondary"}
                            />
                    </DialogContent>
                    }
                </Dialog>

                {hasRights && canevasAnchor ? (
                    <Popper
                        open={
                            isCanevasOpen && isArrayLength(availableTransitions)
                        }
                        anchorEl={canevasAnchor}
                        sx={{ width: "120px" }}
                        placement={"right"}
                    >
                        <ClickAwayListener
                            onClickAway={() => setCanevasAnchor(null)}
                        >
                            <Paper
                                style={{
                                    padding: 4,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "inherit",
                                    alignItems: "inherit",
                                    width: "max-content",
                                }}
                                elevation={6}
                            >
                                <DiagramTransitions
                                    availableTransitions={availableTransitions}
                                    elementID={rowData.ID}
                                    baseURL={baseURL}
                                    beforeTransition={transition => {
                                        beforeTransition(transition);
                                        setIsLoading(true);
                                    }}
                                    afterTransition={transition => {
                                        afterTransition(transition);
                                        setIsLoading(false);
                                    }}
                                    afterTransitionFail={transition => {
                                        setIsLoading(false);
                                    }}
                                    TransitionDialog={transitionDialog}
                                    rowData={rowData}
                                />
                            </Paper>
                        </ClickAwayListener>
                    </Popper>
                ) : null}
            </div>
        )
    );
};

export const LookUpEdit = ({
    icon,
    rowData,
    baseURL = null,
    setCurrentRowId,
    setRefreshGrid,
    disabled = false,
}) => {
    const [canevasAnchor, setCanevasAnchor] = useState(null);
    const isCanevasOpen = Boolean(canevasAnchor);
    const token = useSelector(state => state.auth.Token);
    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const { handleSubmit, setValue } = methods;

    const manageSubmit = data => {
        editModel({ ...data }, rowData.ID, token, baseURL).then(
            setCanevasAnchor(null)
        );
        setRefreshGrid();
    };

    useEffect(() => {
        if (isCanevasOpen) {
            setValue("RequestedAccess", rowData.RequestedAccess);
        }
    }, [rowData, setValue, isCanevasOpen, setCurrentRowId]);

    return (
        <Box>
            <GridActionsCellItem
                icon={icon}
                onClick={event => setCanevasAnchor(event.currentTarget)}
                label="Lookup details"
                disabled={disabled}
            />
            <Popper
                open={Boolean(isCanevasOpen)}
                anchorEl={canevasAnchor}
                style={{ zIndex: 1 }}
                placement={"left-start"}
                keepMounted={false}
            >
                <ClickAwayListener onClickAway={() => setCanevasAnchor(null)}>
                    <Box>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(manageSubmit)}>
                                <Paper
                                    sx={{
                                        width: 600,
                                        borderTop:
                                            "2px solid rgba(0, 0, 0, 0.12)",
                                        mt: 5,
                                        p: 2,
                                        mr: 2,
                                        overflowY: "scroll",
                                    }}
                                    elevation={6}
                                >
                                    <HiddenField
                                        fieldName="ID"
                                        defaultValue={rowData.ID}
                                    />
                                    <HiddenField
                                        fieldName="RequestType"
                                        defaultValue="ADDUSER"
                                    />
                                    <Grid item container>
                                        <Grid item xs={12}>
                                            <VSelect
                                                options={requestedAccessOptions.map(
                                                    e => ({
                                                        ...e,
                                                        label: t(
                                                            `select.${e.label}`
                                                        ),
                                                    })
                                                )}
                                                label={t(
                                                    "field.RequestAccessType"
                                                )}
                                                fieldName={"RequestedAccess"}
                                                validation={{
                                                    required: t(
                                                        "input.validation.required"
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            my: 1,
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            size="small"
                                            sx={{
                                                ml: 1,
                                            }}
                                            onClick={() =>
                                                setCanevasAnchor(null)
                                            }
                                        >
                                            {t("common.Cancel")}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            size="small"
                                            sx={{
                                                ml: 1,
                                            }}
                                        >
                                            {t("common.Save")}
                                        </Button>
                                    </Box>
                                </Paper>
                            </form>
                        </FormProvider>
                    </Box>
                </ClickAwayListener>
            </Popper>
        </Box>
    );
};

export const TranslationDialog = ({
    label = "",
    open,
    setOpen,
    rowParams,
    baseURL,
}) => {
    const token = useSelector(state => state.auth.Token);
    const locale = useSelector(state => state.i18n.locale);

    const defaultNull = {
        ENG: "",
        DEU: "",
        FRA: "",
        NLD: "",
        POR: "",
        SPA: "",
    };

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: defaultNull,
    });
    const { reset, watch, handleSubmit, formState } = methods;

    useEffect(() => {
        let isSubscribed = true;
        const fetching = async () => {
            const translationsFetched = await fetchData(
                `${baseURL}/Translations`,
                token,
                "ID",
                rowParams?.ID
            );
            if (isSubscribed && translationsFetched) {
                setFetchedData(translationsFetched?.data);
            }
        };
        if (rowParams?.ID && open) {
            fetching();
        }
        return () => (isSubscribed = false);
    }, [rowParams, baseURL, reset, token, open]);

    /** Method that will empty the traduction form, because the user can switch between each row and trigger it whitout any refresh or state is updated. */
    function closeAndClearForm() {
        setOpen(false);
        setClearForm(true);
    }

    const [fetchedData, setFetchedData] = useState();
    /** If trigerred, it will reset form with fetched translations */
    useEffect(() => {
        if (fetchedData) {
            reset(mergeFormObjects(fetchedData, defaultNull));
        }
        // eslint-disable-next-line
    }, [fetchedData]);

    const [clearForm, setClearForm] = useState(false);
    /** If trigerred, it will reset form default values (= clear) */
    useEffect(() => {
        if (clearForm) {
            reset(defaultNull);
        }
        // eslint-disable-next-line
    }, [clearForm]);

    const languageOptionsWithoutUserLanguage = languagesOptions.filter(
        element => element.value !== locale
    );

    ////////////////////////////////// TOKEN REVALIDATION \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    const [sessionVerification, setSessionVerification] = useState(false);
    useCheckAndRevalidateToken(setSessionVerification, [
        formState.isSubmitting,
    ]);
    ////////////////////////////////// TOKEN REVALIDATION \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

    /** Specific submit where we send only the keys (= languages) that have a value.  */
    const manageSubmit = async data => {
        let dataObj = data;
        Object.keys(dataObj)?.forEach(key => {
            if (dataObj[key]?.trim() === "") {
                delete dataObj[key];
            }
        });
        let clearedData = dataObj;
        await editModel(
            clearedData,
            rowParams.ID,
            token,
            `${baseURL}/Translations`
        ).then(() => {
            closeAndClearForm();
        });
    };

    const isDisabled = formState.isSubmitting || !formState.isDirty;

    if (!open) {
        return null;
    }
    return (
        <Dialog open={Boolean(open)}>
            {sessionVerification ? (
                <SessionVerificationDialog
                    sessionVerification={sessionVerification}
                />
            ) : null}
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(manageSubmit)}>
                    <DialogTitle>
                        <Box sx={{ typography: "body1", display: "flex" }}>
                            Translate{" "}
                            <Box
                                sx={{
                                    typography: "bodyMajor",
                                    textTransform: "lowercase",
                                    ml: 1 / 2,
                                }}
                            >
                                {label}
                            </Box>{" "}
                            : {rowParams?.Value}
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ mb: 2 }}></DialogContentText>
                        <Grid container spacing={2}>
                            {languageOptionsWithoutUserLanguage.map(
                                (item, index) => {
                                    let fieldNameValue = watch(item.value);
                                    let trimmedValue = fieldNameValue?.trim();
                                    return (
                                        <Grid
                                            key={item.label}
                                            item
                                            container
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                            }}
                                        >
                                            <Grid item xs={1}>
                                                {isArrayLength(trimmedValue) ? (
                                                    <CheckCircleOutlineIcon
                                                        sx={{
                                                            color: "primary.main",
                                                            mt: 1 / 2,
                                                        }}
                                                    />
                                                ) : (
                                                    <CircleOutlinedIcon
                                                        sx={{
                                                            color: "primary.main",
                                                            mt: 1 / 2,
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={11}>
                                                <VTextField
                                                    fullWidth
                                                    label={t(item.label)}
                                                    fieldName={item.value}
                                                />
                                            </Grid>
                                        </Grid>
                                    );
                                }
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActionsForm>
                        <BasicButton
                            type="button"
                            style={{
                                position: "relative",
                                margin: 4,
                            }}
                            onClick={() => closeAndClearForm()}
                        >
                            {t("common.Cancel")}
                        </BasicButton>
                        <BasicButton
                            type="submit"
                            style={{ margin: 4, marginRight: 0 }}
                            disabled={isDisabled}
                        >
                            {t("common.Save")}
                        </BasicButton>
                    </DialogActionsForm>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export const FormDialog = ({
    closeForm,
    label = "",
    manageSubmit,
    manageEdition,
    saveLabel,
    fieldName,
    Content = _fieldName => {
        /* If received as props, this will display embedded JSX snippet, e.g. AdvancedSelectorGrid in ZoneGrid component. */
    },
}) => {
    const token = useSelector(state => state.auth.Token);
    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const { handleSubmit, formState, watch, reset } = methods;
    const { open, type, url, editID } = manageEdition;
    const inEdition = type === "edit";
    const [isLoading, setIsLoading] = useState();
    useEffect(() => {
        let isSubscribed = true;
        const fetching = async () => {
            setIsLoading(true);
            const elementFetched = await fetchData(url, token, "ID", editID);
            reset(elementFetched.data);
            setIsLoading(false);
        };

        inEdition && isSubscribed && fetching();
        return () => (isSubscribed = false);
        // eslint-disable-next-line
    }, [inEdition]);

    const isDisabled =
        formState.isSubmitting ||
        !formState.isDirty ||
        (fieldName && !isArrayLength(watch(fieldName)));

    return (
        <Dialog open={open} sx={{ w: 1 }} fullWidth>
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(data => manageSubmit(data, reset))}
                >
                    {isLoading ? (
                        <ScreenLoading
                            title={t("common.Loading")}
                            sx={{
                                display: "flex",
                                height: "auto",
                                justifyContent: "center",
                            }}
                        />
                    ) : (
                        <>
                            <DialogTitle
                                sx={{ typography: "body1", display: "flex" }}
                            >
                                <Typography
                                    color="secondary"
                                    sx={{ ml: 1 / 2 }}
                                >
                                    {label}
                                </Typography>
                            </DialogTitle>
                            <DialogContent>
                                <Content fieldName={fieldName} />
                            </DialogContent>
                            <DialogActionsForm>
                                <BasicButton
                                    type="button"
                                    style={{
                                        position: "relative",
                                        margin: 4,
                                    }}
                                    onClick={() => {
                                        closeForm(reset);
                                    }}
                                >
                                    {t("common.Cancel")}
                                </BasicButton>
                                <BasicButton
                                    type="submit"
                                    style={{ margin: 4, marginRight: 0 }}
                                    disabled={isDisabled}
                                >
                                    {saveLabel ?? t("common.Save")}
                                </BasicButton>
                                {formState.isSubmitting ? (
                                    <CircularProgress
                                        size={26}
                                        thickness={10}
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            right: "8%",
                                            marginTop: "-12px",
                                            marginLeft: "-8px",
                                        }}
                                    />
                                ) : null}
                            </DialogActionsForm>
                        </>
                    )}
                </form>
            </FormProvider>
        </Dialog>
    );
};
export const BasicDialog = ({
    closeDialog,
    label = "",
    open,
    Content = () => {},
}) => {
    return (
        <Dialog open={open} sx={{ w: 1 }} fullWidth>
            <DialogTitle sx={{ typography: "body1", display: "flex" }}>
                <Typography color="secondary" sx={{ ml: 1 / 2 }}>
                    {label}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Content />
            </DialogContent>
            <DialogActionsForm>
                <BasicButton
                    type="button"
                    style={{
                        position: "relative",
                        margin: 4,
                    }}
                    onClick={() => {
                        closeDialog();
                    }}
                >
                    {t("common.Cancel")}
                </BasicButton>
            </DialogActionsForm>
        </Dialog>
    );
};
