import { Translate, t } from "react-i18nify";
import {
    cellValidationsMerger,
    requiredCell,
    validateNCharCell,
} from "../utils/inputValidations";
import { unitCategories } from "./enumTraductions";

export const oTUser = `/user`; // /!\ : also changed in backend to avoid having the same route
export const internalUser = `/internalUser`;
export const externalUser = `/externalUser`;
export const allUser = `/allUser`;
export const region = {
    label: <Translate value={"grid.title.Regions"} />,
    route: `/Region`,
};
export const family = {
    label: <Translate value={"grid.title.Families"} />,
    route: `/Family`,
};
export const station = { route: `/station` };
export const zone = { label: "map.zonesOf", route: `/zone` };
export const eddFile = {
    label: <Translate value={"grid.title.EDDFiles"} />,
    route: `/eddFile`,
};
export const activeLimit = {
    route: `/activelimit`,
};

export const alertDetail = {
    route: `/alertDetail`,
};
export const limits = `/limits`;
export const limit = {
    label: <Translate value={"grid.title.Limit"} />,
    route: `/limit`,
};

export const bulkUpload = {
    label: <Translate value={"grid.title.BulkUpload"}/>,
    route: `/BulkUpload`
}
export const bulkUploadResult = `/bulkuploadresult`;


export const branch = {
    label: <Translate value={"grid.title.Branches"} />,
    route: `/Branch`,
};
export const country = {
    label: <Translate value={"grid.title.Countries"} />,
    route: `/Country`,
};
export const queries = `/queries`;
export const query = {
    label: <Translate value={"grid.title.Queries"} />,
    route: `/query`,
};
export const reportScheduler = {
    label: <Translate value={"grid.title.ReportScheduler"}/>,
    route: `/ReportScheduler`
}
export const eddGrids = `/Grid/EDDGrids`;

export const analytes = {
    label: <Translate value={"grid.title.Analytes"} />,
    route: `/ref_analyte`,
};
export const analysisGroup = {
    label: <Translate value={"field.AnalysisGroup"} />,
    route: `/ref_analysisgroup`,
};

export const changesHistory = {
    label: <Translate value={"common.ChangesHistory"}/>,
    route: `/history`
}
    
    
export const queryResult = `/queryresult`;
export const oTEntity = `/OTEntity`;
export const companyManager = `/CompanyManager`;
export const holdingManager = `/HoldingManager`;
export const branchManager = `/BranchManager`;
export const entityManager = `/entityManager`;
export const entityUser = `/entityUser`;
export const gisManager = `/gisManager`;
export const lookupHint = `/LookupHint`;
export const newFeaturesMessage = `/NewFeaturesMessage`;
export const oTRight = `/OTRight`;
export const externalProvider = `/ExternalProvider`;
export const oTEntityTreeTemplate = `/OTEntityTreeTemplate`;
export const oTEntityTreeTemplateEditor = `${oTEntityTreeTemplate}/otentitytreetemplates`;
export const studyTreeTemplate = `/StudyTreeTemplate`;
export const studyTreeTemplateEditor = `${studyTreeTemplate}/studytreetemplates`;
export const studyType = `/StudyType`;
export const study = `/Study`;
export const oTEntityTree = "/OTEntityTree";
export const oTEntityTreeGet = "/OTEntityTree/otentitytrees";
export const WorkOrder = "/WorkOrder";
export const StationGroup = "/StationGroup";
export const document = `/document`;
export const sampling = `/sampling`;
export const externalUserWorkOrder = `/OTUserWorkOrder`;
export const request = `/request`;
export const notification = `/notification`;

/** Export ref_routes (cf. controller references). Beware regarding label translation for each one: there's none, it remains in English. */
export const refRoutes = [
    { label: "references.Ref_AirType", route: "/Ref_AirType" },
    { label: "references.Ref_AnalysisGroup", route: "/Ref_AnalysisGroup" },
    { label: "references.Ref_BoreholeStatus", route: "/Ref_BoreholeStatus" },
    {
        label: "references.Ref_BoreholeType",
        route: "/Ref_BoreholeType",
        specificColumns: apiRef => [
            {
                field: "StationCode",
                headerName: t("field.StationCode"),
                width: 200,
                flex: 1,
                editable: true,
                preProcessEditCellProps: params => {
                    const hasError = cellValidationsMerger([
                        () =>
                            requiredCell(
                                params,
                                apiRef,
                                t("field.StationCode"),
                                "StationCode"
                            ),
                        () =>
                            validateNCharCell(
                                params,
                                apiRef,
                                t("field.StationCode"),
                                "StationCode",
                                2
                            ),
                    ]);
                    return {
                        ...params.props,
                        error: hasError,
                    };
                },
            },
        ],
    },
    { label: "references.Ref_CapType", route: "/Ref_CapType" },
    {
        label: "references.Ref_ConstructionType",
        route: "/Ref_ConstructionType",
    },
    { label: "references.Ref_Context", route: "/Ref_Context" },
    { label: "references.Ref_DayNight", route: "/Ref_DayNight" },
    { label: "references.Ref_DrillingFluid", route: "/Ref_DrillingFluid" },
    { label: "references.Ref_DrillingMethod", route: "/Ref_DrillingMethod" },
    {
        label: "references.Ref_EcologicalIntegrity",
        route: "/Ref_EcologicalIntegrity",
    },
    { label: "references.Ref_EmissionSource", route: "/Ref_EmissionSource" },
    { label: "references.Ref_EquipmentType", route: "/Ref_EquipmentType" },
    { label: "references.Ref_GeometryType", route: "/Ref_GeometryType" },
    { label: "references.Ref_HabitatType", route: "/Ref_HabitatType" },
    { label: "references.Ref_Installation", route: "/Ref_Installation" },
    { label: "references.Ref_LithologyType", route: "/Ref_LithologyType" },
    { label: "references.Ref_ObservationMode", route: "/Ref_ObservationMode" },
    { label: "references.Ref_Parameter", route: "/Ref_Parameter" },
    { label: "references.Ref_PiezometerGroup", route: "/Ref_PiezometerGroup" },
    {
        label: "references.Ref_RelativePosition",
        route: "/Ref_RelativePosition",
    },
    { label: "references.Ref_ReleaseLocation", route: "/Ref_ReleaseLocation" },
    {
        label: "references.Ref_StationBiodiversityType",
        route: "/Ref_StationBiodiversityType",
    },
    {
        label: "references.Ref_StationSedimentWaterType",
        route: "/Ref_StationSedimentWaterType",
        specificColumns: apiRef => [
            {
                field: "StationCode",
                headerName: t("field.StationCode"),
                width: 200,
                flex: 1,
                editable: true,
                preProcessEditCellProps: params => {
                    const hasError = cellValidationsMerger([
                        () =>
                            requiredCell(
                                params,
                                apiRef,
                                t("field.StationCode"),
                                "StationCode"
                            ),
                        () =>
                            validateNCharCell(
                                params,
                                apiRef,
                                t("field.StationCode"),
                                "StationCode",
                                2
                            ),
                    ]);
                    return {
                        ...params.props,
                        error: hasError,
                    };
                },
            },
        ],
    },
    {
        label: "references.Ref_StratigraphicClassification",
        route: "/Ref_StratigraphicClassification",
    },
    { label: "references.Ref_Vulnerability", route: "/Ref_Vulnerability" },
    {
        label: "references.Ref_WaterIntermittence",
        route: "/Ref_WaterIntermittence",
    },
    { label: "references.Ref_WaterLocation", route: "/Ref_WaterLocation" },
    { label: "references.Ref_WaterSource", route: "/Ref_WaterSource" },
    { label: "references.Ref_AquiferStatus", route: "/Ref_AquiferStatus" },
    {
        label: "references.Ref_BiodiversityType",
        route: "/Ref_BiodiversityType",
    },
    { label: "references.Ref_EcologicalGroup", route: "/Ref_EcologicalGroup" },
    { label: "references.Ref_TrophicGroup", route: "/Ref_TrophicGroup" },
    { label: "references.Ref_FaunaUse", route: "/Ref_FaunaUse" },
    { label: "references.Ref_HumanUse", route: "/Ref_HumanUse" },
    { label: "references.Ref_IUCNStatus", route: "/Ref_IUCNStatus" },
    { label: "references.Ref_Matrix", route: "/Ref_Matrix" },
    { label: "references.Ref_ObservationType", route: "/Ref_ObservationType" },
    { label: "references.Ref_ObsMeasure", route: "/Ref_ObsMeasure" },
    { label: "references.Ref_PresenceProof", route: "/Ref_PresenceProof" },
    { label: "references.Ref_ReadingType", route: "/Ref_ReadingType" },
    { label: "references.Ref_RecordPeriod", route: "/Ref_RecordPeriod" },
    { label: "references.Ref_PhotoContext", route: "/Ref_PhotoContext" },
    { label: "references.Ref_QAQCType", route: "/Ref_QAQCType" },
    { label: "references.Ref_SampleType", route: "/Ref_SampleType" },
    {
        label: "references.Ref_SampleCollectionType",
        route: "/Ref_SampleCollectionType",
    },
    { label: "references.Ref_SensorType", route: "/Ref_SensorType" },
    { label: "references.Ref_WaterType", route: "/Ref_WaterType" },
    { label: "references.Ref_WeightBasis", route: "/Ref_WeightBasis" },
    {
        label: "references.Ref_Unit",
        route: "/Ref_Unit",
        readOnly: true,
        specificColumns: () => [
            {
                field: "Category",
                headerName: t("field.Category"),
                width: 200,
                flex: 1,
                editable: false,
                valueFormatter: params => t(unitCategories[params.value]),
            },
            {
                field: "Description",
                headerName: t("field.Description"),
                width: 300,
                editable: false,
            },
        ],
    },
];

export const filter = {
    genericFilter: (field, value, several) =>
        `${several ? "&" : ""}filter[${field}]=${value}`,
    internalFilter: "filter[External]=false",
    externalFilter: "filter[External]=true",
    allUsersFilter: "filter[New]=true",
    allFilter: "filter[ALL]=true",
    entityIDFilter: ID => `filter[OTEntityID]=${ID}`,
};
