import React from "react"
import { Box } from "@mui/system"
import { Chip, Grid, IconButton, Paper, Typography } from "@mui/material"
import HelpIcon from "@mui/icons-material/HelpOutlineOutlined";
import { t } from "react-i18nify";
import { BasicTooltip } from "../../Widgets/StyledComponents";
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

// Previously, from : src/components/Views/DocumentsView.js

export const mimeTypeCategories = {
    PhotoVideo: [
        {
            Extension: "jpeg",
            Name: "JPEG image"
        },
        {
            Extension: "jpg",
            Name: "JPEG image"
        },
        {
            Extension: "mp3",
            Name: "MP3 audio file"
        },
        {
            Extension: "mp4",
            Name: "MPEG-4 video file"
        },
        {
            Extension: "mpeg",
            Name: "MPEG video file"
        },
        {
            Extension: "doc",
            Name: "Word document"
        },
        {
            Extension: "docx",
            Name: "Word document"
        },
        {
            Extension: "png",
            Name: "Portable Network Graphics image"
        },
        {
            Extension: "svg",
            Name: "Scalable Vector Graphics"
        },
        {
            Extension: "tif",
            Name: "Tagged Image File Format image"
        },
        {
            Extension: "tiff",
            Name: "Tagged Image File Format image"
        },
        {
            Extension: "wav",
            Name: "WAVE audio file"
        },
        {
            Extension: "avchd",
            Name: "AVCHD video file"
        },
        {
            Extension: ".avi",
            Name: "Audio Video Interleave file"
        }
    ],
    Documents: [
        {
            Extension: "odp",
            Name: "OpenDocument presentation"
        },
        {
            Extension: "ods",
            Name: "OpenDocument spreadsheet"
        },
        {
            Extension: "odt",
            Name: "OpenDocument text document"
        },
        {
            Extension: "pdf",
            Name: "Portable Document Format"
        },
        {
            Extension: "ppt",
            Name: "PowerPoint presentation"
        },
        {
            Extension: "pptx",
            Name: "PowerPoint presentation"
        },
        {
            Extension: "rar",
            Name: "RAR compressed archive"
        },
        {
            Extension: "rtf",
            Name: "Rich Text Format"
        },
        {
            Extension: "txt",
            Name: "Plain text file"
        },
        {
            Extension: "vsd",
            Name: "Visio drawing"
        },
        {
            Extension: "xls",
            Name: "Excel spreadsheet"
        },
        {
            Extension: "xlsx",
            Name: "Excel spreadsheet"
        },
        {
            Extension: "zip",
            Name: "ZIP compressed archive"
        },
        {
            Extension: "7z",
            Name: "7-zip compressed archive"
        },
        {
            Extension: "dwg",
            Name: "AutoCAD drawing"
        },
        {
            Extension: "dxf",
            Name: "AutoCAD drawing exchange format"
        },
        {
            Extension: "shx",
            Name: "AutoCAD shape entension"
        },
        {
            Extension: "shp",
            Name: "ESRI shapefile"
        },
        {
            Extension: "kmz",
            Name: "KMZ compressed geospatial data"
        },
        {
            Extension: ".cpg",
            Name: "ESRI Code Page"
        },
        {
            Extension: ".dbf",
            Name: "dBASE Table"
        },
        {
            Extension: ".prj",
            Name: "ESRI Projection"
        },
        {
            Extension: ".sbn",
            Name: "ESRI Spatial Index"
        },
        {
            Extension: ".sbx",
            Name: "ESRI Spatial Index"
        },
        {
            Extension: ".shp.xml",
            Name: "ESRI Shapefile XML Metadata"
        },
        {
            Extension: ".xlsm",
            Name: "Excel spreadsheet (Macros)"
        },
        {
            Extension: ".csv",
            Name: "Comma-separated Values File"
        }
    ]
};

export const bulkUploadFilesCategories = {
    ZipArchives: [
        {
            Extension: "zip",
            Name: "ZIP compressed archive"
        },
        {
            Extension: "7z",
            Name: "7-zip compressed archive"
        }
    ]
}

export const BulkUploadInstructions = ({
    editingRights,
    toggleInfo,
    setToggleInfo,
    isDragActive,
    isDragReject,
    fileType,
    fileRejections,
}) => {

    const bulkUploadFileTypeArray = Object.values(bulkUploadFilesCategories)
    const concatMimeTypeArray = bulkUploadFileTypeArray[0]

    return (
        <>
            {
                !editingRights ? null : (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "left",
                        }}
                    >
                        {/* check also for:  fileType */}
                        {toggleInfo && (
                            <Paper
                                elevation={3}
                                sx={{
                                    my: 2,
                                    p: 2,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <IconButton
                                    onClick={_e => setToggleInfo(!toggleInfo)}
                                >
                                    <HelpIcon color="primary" />
                                </IconButton>
                                <Box sx={{ display: "block", ml: 2 }}>
                                    {!isDragActive && (
                                        <>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    mr: 2,
                                                    fontStyle: "italic",
                                                }}
                                            >
                                                {t("view.document.DragAndDropFile")}{" "}
                                            </Typography>
                                        </>
                                        
                                    )}
                                    {isDragReject && (
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                mr: 2,
                                                fontStyle: "italic",
                                            }}
                                        >
                                            {t("view.document.FilesRejected")}
                                        </Typography>
                                    )}
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            mr: 2,
                                            fontStyle: "italic",
                                        }}
                                        color={
                                            fileRejections[0]?.errors?.[0]
                                                ?.message
                                                ? "secondary"
                                                : null
                                        }
                                    >
                                        {t("view.document.MaxFilesAllowed")}
                                    </Typography>
                                    {isDragActive && !isDragReject && (
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                mr: 2,
                                                fontStyle: "italic",
                                            }}
                                        >
                                            {t(
                                                "input.validation.DropFileAccepted"
                                            )}
                                        </Typography>
                                    )}
                                    {fileType && (
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="body2"
                                                color={
                                                    fileRejections[0]
                                                        ?.errors?.[0]?.message
                                                        ? "secondary"
                                                        : null
                                                }
                                                sx={{
                                                    mr: 2,
                                                    fontStyle: "italic",
                                                }}
                                            >
                                                {fileType === "Documents"
                                                    ? t(
                                                        "input.validation.WrongDocumentExtension"
                                                    )
                                                    : t(
                                                        "input.validation.WrongPhotoVideoExtension"
                                                    )}
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Box
                                        sx={{
                                            pt: 1
                                        }}
                                    >
                                    <BasicTooltip
                                        placement="bottom"
                                        title={
                                            <Box component="span">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>{t("view.document.Extension")}</th>
                                                            <th>{t("field.Name")}</th>
                                                        </tr>
                                                    </thead>
                                                    {fileType ?
                                                        <tbody>
                                                            {mimeTypeCategories[fileType].map((item, index) => {
                                                                    return (
                                                                        <tr key={item.Extension}>
                                                                            <td>{item.Extension}</td>
                                                                            <td>{item.Name}</td>
                                                                        </tr>
                                                                    )
                                                            })}
                                                        </tbody>
                                                        :
                                                        <tbody>
                                                            {concatMimeTypeArray?.map((item, index) => {
                                                                return (
                                                                    <tr key={item.Extension}>
                                                                        <td>{item.Extension}</td>
                                                                        <td>{item.Name}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                }
                                                </table>
                                            </Box>
                                        }
                                    >
                                        <Chip
                                            icon={<ContentPasteSearchIcon/>}
                                            sx={{ cursor: "pointer", p:0, m:0 }}
                                            label={t("view.document.ListAllowedExtensions")}
                                        />
                                        </BasicTooltip>
                                    </Box>
                                </Box>
                            </Paper>
                        )}
                    </Box>
                )
            }
        
        </>
    )
    
}

export const DocumentUploadInstructions = ({
    editingRights,
    toggleInfo,
    setToggleInfo,
    isDragActive,
    isDragReject,
    fileType,
    fileRejections,
}) => {

    const mimeTypeArray = Object.values(mimeTypeCategories)
    const concatMimeTypeArray = mimeTypeArray[0].concat(mimeTypeArray[1])

    return (
        <>
            {
                !editingRights ? null : (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "left",
                        }}
                    >
                        {/* check also for:  fileType */}
                        {toggleInfo && (
                            <Paper
                                elevation={3}
                                sx={{
                                    my: 2,
                                    p: 2,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <IconButton
                                    onClick={_e => setToggleInfo(!toggleInfo)}
                                >
                                    <HelpIcon color="primary" />
                                </IconButton>
                                <Box sx={{ display: "block", ml: 2 }}>
                                    {!isDragActive && (
                                        <>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    mr: 2,
                                                    fontStyle: "italic",
                                                }}
                                            >
                                                {t("view.document.DragAndDropFile")}{" "}
                                            </Typography>
                                        </>

                                    )}
                                    {isDragReject && (
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                mr: 2,
                                                fontStyle: "italic",
                                            }}
                                        >
                                            {t("view.document.FilesRejected")}
                                        </Typography>
                                    )}
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            mr: 2,
                                            fontStyle: "italic",
                                        }}
                                        color={
                                            fileRejections[0]?.errors?.[0]
                                                ?.message
                                                ? "secondary"
                                                : null
                                        }
                                    >
                                        {t("view.document.MaxFilesAllowed")}
                                    </Typography>
                                    {isDragActive && !isDragReject && (
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                mr: 2,
                                                fontStyle: "italic",
                                            }}
                                        >
                                            {t(
                                                "input.validation.DropFileAccepted"
                                            )}
                                        </Typography>
                                    )}
                                    {fileType && (
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="body2"
                                                color={
                                                    fileRejections[0]
                                                        ?.errors?.[0]?.message
                                                        ? "secondary"
                                                        : null
                                                }
                                                sx={{
                                                    mr: 2,
                                                    fontStyle: "italic",
                                                }}
                                            >
                                                {fileType === "Documents"
                                                    ? t(
                                                        "input.validation.WrongDocumentExtension"
                                                    )
                                                    : t(
                                                        "input.validation.WrongPhotoVideoExtension"
                                                    )}
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Box
                                        sx={{
                                            pt: 1
                                        }}
                                    >
                                        <BasicTooltip
                                            placement="bottom"
                                            title={
                                                <Box component="span">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>{t("view.document.Extension")}</th>
                                                                <th>{t("field.Name")}</th>
                                                            </tr>
                                                        </thead>
                                                        {fileType ?
                                                            <tbody>
                                                                {mimeTypeCategories[fileType].map((item, index) => {
                                                                    return (
                                                                        <tr key={item.Extension}>
                                                                            <td>{item.Extension}</td>
                                                                            <td>{item.Name}</td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                            :
                                                            <tbody>
                                                                {concatMimeTypeArray?.map((item, index) => {
                                                                    return (
                                                                        <tr key={item.Extension}>
                                                                            <td>{item.Extension}</td>
                                                                            <td>{item.Name}</td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        }
                                                    </table>
                                                </Box>
                                            }
                                        >
                                            <Chip
                                                icon={<ContentPasteSearchIcon />}
                                                sx={{ cursor: "pointer", p: 0, m: 0 }}
                                                label={t("view.document.ListAllowedExtensions")}
                                            />
                                        </BasicTooltip>
                                    </Box>
                                </Box>
                            </Paper>
                        )}
                    </Box>
                )
            }

        </>
    )

} 
